@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.navbar {
  position: absolute;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.46);
  border-top-right-radius: 0;
  overflow: hidden;
  backdrop-filter: blur(23px);
  border: 1px solid rgba(255, 255, 255, 0.62);
  height: 100%;
}
@media (max-width: 560px) {
  .navbar {
    width: 100%;
    height: 100%;
  }
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text-placeholder {
  border-radius: 5px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;

  padding-left: 30px;
  flex-direction: column;
}

.text {
  color: #030303;
  font-size: 27px;
  font-family: "inter";
  font-weight: 600;
}
